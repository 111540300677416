import Entity from './entity'

const fields = {
  UserAccess: {
    type: Boolean
  },
  SystemAccess: {
    type: Boolean
  }
}

const XeroAccessToken = new Entity('XeroAccessToken', fields)

XeroAccessToken.createQuery('getAccess', 'xeroAccessTokenGet')

export default XeroAccessToken
