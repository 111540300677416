<template>
  <nav class="wv-nav">
    <b-collapse id="collapse-navbar">
      <div class="wv-navbar__container">
        <ul class="wv-navbar__list wv-navbar__list--primary">
          <li
            v-for="(item, index) in mainItems"
            :key="index"
            :class="{ 'active': isActive(item) }"
            class="wv-nav__link"
          >
            <nuxt-link v-d-toggle="`snc-${index}`" :to="item.to" class="p-1 text-nowrap" :class="item.class">
              {{ item.title }}
            </nuxt-link>
          </li>
        </ul>
        <ul class="wv-navbar__list wv-navbar__list--secondary">
          <b-nav-item-dropdown class="wv-nav__link user-icon">
            <template slot="button-content">
              <img
                v-b-tooltip.left.hover
                :class="imgClass"
                :title="tooltip"
                :src="'https://api.dicebear.com/7.x/initials/svg?seed=' + user.name + '&size=21&radius=50&backgroundType=gradientLinear&backgroundColor='+imgColor"
              >
            </template>
            <b-dropdown-item @click="logout">
              Sign Out
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </ul>
      </div>
    </b-collapse>
    <a v-b-toggle.collapse-navbar href="#" class="collapse-button">
      <span class="material-icons">
        menu
      </span>
    </a>
  </nav>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default() {
        return []
      }
    },
    mainItems: {
      type: Array,
      default() {
        return []
      }
    },
    user: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  computed: {
    xeroUserActive() {
      return this.$store.getters['xero/xero/getAccess'].userAccess
    },
    xeroSystemActive() {
      return this.$store.getters['xero/xero/getAccess'].systemAccess
    },
    imgColor() {
      if (this.xeroUserActive === true && this.xeroSystemActive === true) {
        return '43a047'
      } else if (
        this.xeroUserActive === true &&
        this.xeroSystemActive === false
      ) {
        return '5e35b1'
      } else if (
        this.xeroUserActive === false &&
        this.xeroSystemActive === true
      ) {
        return 'fb8c00'
      } else if (
        this.xeroUserActive === false &&
        this.xeroSystemActive === false
      ) {
        return 'e53935'
      } else {
        return '1f35f4'
      }
    },
    imgClass() {
      return {
        'xero-border': true,
        'checking-access':
          this.xeroUserActive === null || this.xeroSystemActive === null,
        'user-access':
          this.xeroUserActive === true && this.xeroSystemActive === false,
        'no-access':
          this.xeroUserActive === false && this.xeroSystemActive === false,
        'system-access':
          this.xeroUserActive === false && this.xeroSystemActive === true,
        'full-access':
          this.xeroUserActive === true && this.xeroSystemActive === true
      }
    },
    tooltip() {
      let accessLevel

      if (this.xeroUserActive === true && this.xeroSystemActive === true) {
        accessLevel = ''
      } else if (
        this.xeroUserActive === true &&
        this.xeroSystemActive === false
      ) {
        accessLevel = ''
      } else if (
        this.xeroUserActive === false &&
        this.xeroSystemActive === true
      ) {
        accessLevel = ''
      } else if (
        this.xeroUserActive === false &&
        this.xeroSystemActive === false
      ) {
        accessLevel = ' - Xero Disconnected'
      } else {
        accessLevel = ' - Xero Connecting'
      }

      return `${this.user.name} (${this.user.email})${accessLevel}`
    }
  },
  methods: {
    menuAction(item) {
      if (item.action) {
        item.action()
      }
    },
    logout() {
      this.$store.dispatch('user/removeToken')
      window.location.reload()
    },
    isActive(item) {
      if (!this.$route) {
        return false
      }

      const routeFirst = this.$route.name.split('-')[0]
      const itemFirst = item.to.name.split('-')[0]

      // console.log(routeFirst, itemFirst)

      const routeSecond = this.$route.name.split('-')[1]
      const itemSecond = item.to.name.split('-')[1]

      if (routeFirst === 'reserves' && itemFirst === 'reserves') {
        return routeSecond === itemSecond
      }

      if (routeFirst === 'wine' && itemFirst === 'wine') {
        return routeSecond === itemSecond
      }
      return itemFirst === routeFirst
    }
  }
}
</script>
<style>
.xero-border {
  border: 2px solid;
  border-radius: 9999px;
  border-color: transparent;
}

/* .no-access {
  border-color: #e53935;
}

.system-access {
  border-color: #fb8c00;
}

.user-access {
  border-color: #5e35b1;
}

.full-access {
  border-color: #43a047;
} */

.checking-access {
  animation: pulseBorder 2s infinite;
}

@keyframes pulseBorder {
  0% {
    border-color: black;
  }
  50% {
    border-color: white;
  }
  100% {
    border-color: black;
  }
}

.user-icon a {
  height: 37px !important;
  margin-top: 3px !important;
}
</style>
