<template>
  <form ref="form" method="post" :action="actionUrl" :target="target">
    <input type="hidden" name="token" :value="$store.state.user.documentToken">
    <input type="hidden" name="SOID" :value="soid">
    <input type="hidden" name="Type" :value="type">
    <slot name="form-override">
      <label style="font-size: 12px; text-align: center; width: 100%; margin-bottom: 0 !important; line-height: 12px; display: block;" class="mt-1">
        PDF
      </label>
      <b-button-group class="d-flex" size="sm">
        <b-button block class="wv-btn wv-btn--xs mt-1" :disabled="disabled" @click="handleSubmit('salesOrder')">
          SO
        </b-button>
        <b-button block class="wv-btn wv-btn--xs mt-1" :disabled="disabled" @click="handleSubmit('invoice')">
          Invoice
        </b-button>
      </b-button-group>
    </slot>
  </form>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    soid: {
      type: [Number, String],
      required: true
    },
    type: {
      type: String,
      default: 'pdf'
    },
    target: {
      type: String,
      default: 'self'
    },
    isStorage: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      document: 'salesOrder'
    }
  },
  computed: {
    actionUrl() {
      return process.env.apiHost + '/document/' + this.documentType
    },
    documentType() {
      return this.document === 'invoice'
        ? 'invoice'
        : this.isStorage
          ? 'storageSalesOrder'
          : 'salesOrder'
    }
  },
  methods: {
    handleSubmit(document = 'salesOrder') {
      if (['salesOrder', 'invoice'].includes(document)) {
        this.document = document
      }
      this.submit()
    },
    submit() {
      this.$nextTick(() => {
        this.$refs.form.submit()
      })
    }
  }
}
</script>
