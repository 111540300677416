import Entity from './entity'

const fields = {
  InvoiceNumber: {
    type: String
  },
  XeroInvoiceID: {
    type: String
  },
  XeroURL: {
    type: String
  },
  Reference: {
    type: String
  },
  Status: {
    type: String
  },
  PaidStatus: {
    type: Number
  },
  PaidDate: {
    type: String
  },
  UpdatedDate: {
    type: String
  },
  Enabled: {
    type: Boolean
  },
  IsFinalised: {
    type: Boolean
  },
  IsPosted: {
    type: Boolean
  },
  Total: {
    type: Number
  },
  AmountPaid: {
    type: Number
  },
  AmountDue: {
    type: Number
  },
  DueDate: {
    type: String
  },
  Date: {
    type: String
  },
  ExpectedPaymentDate: {
    type: String
  },
  SODSID: {
    type: Number
  },
  Payments: {
    type: Array,
    fields: {
      Date: {
        type: String
      },
      Amount: {
        type: Number
      },
      IsPayment: {
        type: Boolean
      },
      Reference: {
        type: String
      }
    }
  }
}

const XeroInvoice = new Entity('XeroInvoice', {
  Invoices: {
    type: Array,
    fields: fields
  },
  Enabled: {
    type: Boolean
  }
})

XeroInvoice.createQuery('get', 'xeroSalesLedger')

export default XeroInvoice
