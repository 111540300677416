import { client } from '~/api/graphQLInstance'
import XeroInvoice from '~/models/xeroInvoice'

export const getSalesLedger = async id => {
  const result = await client.query({
    query: XeroInvoice.query('get', {
      ContactID: id
    })
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[XeroInvoice.queries.get.name]
}
