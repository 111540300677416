import { mapState } from 'vuex'

const mapUserSettings = (key, type) => {
  return {
    get() {
      return this.$store.state.user.settings[key]
    },
    set(value) {
      switch (type) {
        case Number:
          this.$store.dispatch('user/setSetting', {
            key,
            value: parseFloat(value)
          })
          break
        case Boolean:
          this.$store.dispatch('user/setSetting', {
            key,
            value: !!value
          })
          break
        default:
          this.$store.dispatch('user/setSetting', { key, value })
      }
    }
  }
}

export default {
  data() {
    return {
      accessControlKey: ''
    }
  },
  computed: {
    ...mapState('user', {
      userSettings: state => state.settings
    }),
    userHideZeroStock: mapUserSettings('hideZeroStock'),
    userEmptyCasesOnly: mapUserSettings('emptyCasesOnly'),
    userShowCategoriesAndAppellations: mapUserSettings(
      'showCategoriesAndAppellations'
    ),
    userDateFormat: mapUserSettings('dateFormat'),
    userWineBrowserPdfFilePreview: mapUserSettings('wineBrowserPdfFilePreview'),
    userWineBrowserPdfExportType: mapUserSettings('wineBrowserPdfExportType'),
    userWineBrowserPdfFileType: mapUserSettings('wineBrowserPdfFileType'),
    userWineBrowserPdfHeading: mapUserSettings('wineBrowserPdfHeading'),
    userPurchaseOrderPdfFilePreview: mapUserSettings(
      'purchaseOrderPdfFilePreview'
    ),
    userPurchaseOrderPdfExportType: mapUserSettings(
      'purchaseOrderPdfExportType'
    ),
    userPurchaseOrderPdfFileType: mapUserSettings('purchaseOrderPdfFileType'),
    userSortExclusions: mapUserSettings('sortExclusions'),
    userPurchaseOrderPdfHeading: mapUserSettings('purchaseOrderPdfHeading'),
    userShowOtherCaseSizes: mapUserSettings('showOtherCaseSizes'),
    userShowAllSellers: mapUserSettings('showAllSellers'),
    userShowOrderLines: mapUserSettings('showOrderLines'),
    userShowMonthlyTotals: mapUserSettings('showMonthlyTotals'),
    userSortByWine: mapUserSettings('sortByWine'),
    userLandscapeShippingInvoice: mapUserSettings('landscapeShippingInvoice'),
    userReportPriceListContent: mapUserSettings('reportPriceListContent'),
    userReportPriceListRecipientDays: mapUserSettings(
      'reportPriceListRecipientDays'
    ),
    userReportPriceListFileType: mapUserSettings('reportPriceListFileType'),
    userDefaultPurchaseOrderUsageId: mapUserSettings(
      'defaultPurchaseOrderUsageId',
      Number
    ),
    userDefaultSalesOrderUsageId: mapUserSettings(
      'defaultSalesOrderUsageId',
      Number
    ),
    userCustomerReviewCompanyPrefix: mapUserSettings(
      'customerReviewCompanyPrefix'
    ),
    userCustomerReviewMinEmailDays: mapUserSettings(
      'customerReviewMinEmailDays',
      Number
    ),
    userCustomerReviewMinAutoEmailDays: mapUserSettings(
      'customerReviewMinAutoEmailDays',
      Number
    ),
    userCustomerReviewMinWineDays: mapUserSettings('customerReviewMinWineDays'),
    userCustomerReviewMaxHighPriceCheckDays: mapUserSettings(
      'customerReviewMaxHighPriceCheckDays',
      Number
    ),
    userCustomerReviewExistingSageAccount: mapUserSettings(
      'customerReviewExistingSageAccount',
      Boolean
    ),
    userCustomerReviewAListOnly: mapUserSettings(
      'customerReviewAListOnly',
      Boolean
    ),
    userCustomerReviewFastStock: mapUserSettings(
      'customerReviewFastStock',
      Boolean
    ),
    // New Reviews
    userContactReviewCompanyPrefix: mapUserSettings(
      'contactReviewCompanyPrefix'
    ),
    userContactReviewExistingSageAccount: mapUserSettings(
      'contactReviewExistingSageAccount',
      Boolean
    ),
    userContactReviewHasValidWines: mapUserSettings(
      'contactReviewHasValidWines',
      Boolean
    ),
    userContactReviewHasEmailAddress: mapUserSettings(
      'contactReviewHasEmailAddress'
    ),
    userContactReviewIsDoNotReview: mapUserSettings(
      'contactReviewIsDoNotReview'
    ),
    userSendPromoEmailCopy: mapUserSettings('sendPromoEmailCopy', Boolean),
    userCopyInEmails: mapUserSettings('copyInEmails', Boolean),
    isOfficeUser() {
      return this.$store.getters['user/isOfficeUser']
    },
    isWarehouseUser() {
      return this.$store.getters['user/isWarehouseUser']
    },
    userToken() {
      return this.$store.state.user.token
    }
  },
  methods: {
    userCan(permission) {
      return this.$store.getters['user/userCan'](
        this.accessControlKey,
        permission
      )
    }
  }
}
