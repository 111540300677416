<template>
  <div style="display: grid; grid-template-columns: repeat(3, 1fr); gap: 10px;">
    <group-box v-if="loading || livExBidOffer.length === 0" style="grid-column: span 3;"> 
      <template slot="header">
        Liv Ex
      </template>
      <div v-if="loading" class="loading-container loading" style="position: relative; width: 100%; height: 10px">
&nbsp;
      </div>
      <template v-else-if="livExBidOffer.length === 0">
        <p class="text-center" style="font-weight: bold; font-size: .8rem; line-height: .8rem; margin-bottom: 2px;">No Results Found</p>
      </template>
    </group-box>
    <template v-if="!loading">
      <group-box v-for="(item) in livExBidOffer" :key="item.format" class="bordeaux-index">
        <template slot="header">
          LivEx - {{ item.format }}
        </template>
        <b-row>
          <b-col class="col-12 col-xxl-9">
            <b-row>
              <b-col class="col-12 col-xxl-6">
                <template v-if="item.bidQuantity">
                  <b-form-group
                    v-b-tooltip.hover.left
                    label="B"
                    label-for="bidPrice"
                    label-size="xs"
                    label-cols-xxl="2"
                    class="mb-0"
                    :title="'Best Bid Price: '+ formatCurrency(item.bidPrice)"
                  >
                    <b-form-input
                      id="bidPrice"
                      :value="formatMoney(item.bidPrice)"
                      size="xs"
                      disabled
                      class="text-right font-weight-bold"
                    />
                  </b-form-group>
                  <b-form-group
                    v-b-tooltip.hover.left
                    label=""
                    label-for="bidPrice4"
                    label-size="sm"
                    label-cols-xxl="2"
                    class="mb-0 mt-1"
                    :title="'Best Bid Price -2%: '+ formatCurrency(item.bidPrice * .98)"
                  >
                    <b-form-input
                      id="bidPrice4"
                      :value="formatMoney(item.bidPrice * .98)"
                      size="sm"
                      disabled
                      class="text-right font-weight-bold"
                    />
                  </b-form-group>
                  <b-form-group
                    v-if="item.bidQuantity"
                    label="Qty"
                    label-for="bidQuantity"
                    label-size="xs"
                    label-cols-xxl="6"
                    class="mb-0 mt-1"
                  >
                    <b-form-input
                      id="bidQuantity"
                      :value="formatQuantity(item.bidQuantity)"
                      size="xs"
                      disabled
                      class="text-right font-weight-bold"
                    />
                  </b-form-group>
                  <b-form-group
                    label=""
                    label-for="bidDate"
                    label-size="xs"
                    label-cols-xxl="1"
                    class="mb-0"
                  >
                    <b-form-input
                      id="bidDate"
                      :value="formatDate(item.bidDate)"
                      size="xs"
                      disabled
                      class="text-right mb-0 mt-1"
                    />
                  </b-form-group>
                </template>
                <template v-else>
                  <p class="text-center" style="font-size: .8rem; line-height: 2rem; margin-bottom: 0px; font-weight: bold;">No Bids</p>
                </template>
              </b-col>
              <b-col class="col-12 col-xxl-6">
                <template v-if="item.offerQuantity">
                  <b-form-group
                    v-b-tooltip.hover.right
                    label="O"
                    label-for="offerPrice"
                    label-size="xs"
                    label-cols-xxl="2"
                    class="mb-0"
                    :title="'Best Bid Price: '+ formatCurrency(item.offerPrice)"
                  >
                    <b-form-input
                      id="offerPrice"
                      :value="formatMoney(item.offerPrice)"
                      size="xs"
                      disabled
                      class="text-right font-weight-bold"
                    />
                  </b-form-group>
                  <b-form-group
                    v-b-tooltip.hover.right
                    label=""
                    label-for="offerPrice2"
                    label-size="sm"
                    label-cols-xxl="2"
                    class="mb-0 mt-1"
                    :title="'Best Offer Price +2%: '+ formatCurrency(item.offerPrice * 1.02)"
                  >
                    <b-form-input
                      id="offerPrice2"
                      :value="formatMoney(item.offerPrice * 1.02)"
                      size="sm"
                      disabled
                      class="text-right font-weight-bold"
                    />
                  </b-form-group>
                  <b-form-group
                    v-if="item.offerQuantity"
                    label="Qty"
                    label-for="offerQuantity"
                    label-size="xs"
                    label-cols-xxl="6"
                    class="mb-0 mt-1"
                  >
                    <b-form-input
                      id="offerQuantity"
                      :value="formatQuantity(item.offerQuantity)"
                      size="xs"
                      disabled
                      class="text-right font-weight-bold"
                    />
                  </b-form-group>
                  <b-form-group
                    label=""
                    label-for="offerDate"
                    label-size="xs"
                    label-cols-xxl="1"
                    class="mb-0"
                  >
                    <b-form-input
                      id="offerDate"
                      :value="formatDate(item.offerDate)"
                      size="xs"
                      disabled
                      class="text-right mb-0 mt-1"
                    />
                  </b-form-group>
                </template>

                <template v-else>
                  <p class="text-center" style="font-size: .8rem; line-height: 2rem; margin-bottom: 0px; font-weight:bold;">No Offers</p>
                </template>
              </b-col>
            </b-row>
          </b-col>
          <b-col class="col-12 col-xxl-3" style="padding-left: 0">
            <b-form-group
              v-if="markup(item)"
              v-b-tooltip.hover.right
              label=""
              label-for="markupPrice"
              label-size="sm"
              label-cols-xxl="0"
              class="mb-0"
              :title="'Markup: '+ formatMarkup(item)"
            >
              <b-form-input
                id="markupPrice"
              
                :value="formatMarkup(item)"
                size="sm"
                disabled
                class="text-right font-weight-bold"
                :class="comparisonClass(markup(item))"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="item.lastTradePrice">
          <b-col class="col-12 col-xxl-7" style="padding-left: 0">
            <b-form-group
              label="Last Trade"
              label-for="lastTradePrice"
              label-size="xs"
              label-cols-xxl="5"
              class="mb-0 mt-1"
            >
              <b-form-input
                id="lastTradePrice"
                :value="formatMoney(item.lastTradePrice)"
                size="sm"
                disabled
                class="text-right font-weight-bold"
              />
            </b-form-group>
          </b-col>
          <b-col v-if="item.lastTradeDate" class="col-12 col-xxl-5" style="padding-left: 0">
            <b-form-input
              id="lastTradeDate"
              :value="formatDate(item.lastTradeDate)"
              size="sm"
              disabled
              class="text-right font-weight-bold mb-0 mt-1"
            />
          </b-col>
        </b-row>
      </group-box>
    </template>
  </div>
</template>

<script>
import GroupBox from '~/components/Base/GroupBox'
import { mapState } from 'vuex'
import moment from 'moment'

export default {
  components: {
    GroupBox
  },
  computed: {
    ...mapState('wineCard/activeCard', {
      livExBidOffer: state => state.livExBidOffer,
      loading: state => state.livExBidOfferLoading,
      error: state => state.livExBidOfferError
    }),
    markup() {
      return function(item) {
        if (item.bidPrice > 0 && item.offerPrice > 0) {
          return Number(
            ((item.offerPrice - item.bidPrice) * 100) / item.bidPrice
          ).toFixed(1)
        } else {
          return null
        }
      }
    },
    formatMarkup() {
      return function(item) {
        if (this.markup(item) !== null) {
          return this.markup(item) + '%'
        } else {
          return 'N/A'
        }
      }
    },
    formatMoney() {
      return function(item) {
        return item > 0
          ? this.$options.filters.withCommas(
              this.$options.filters.round(item, 0),
              0
            )
          : '-'
      }
    },
    formatCurrency() {
      return function(item) {
        return item > 0
          ? this.$options.filters.currency(this.$options.filters.round(item, 0))
          : 'N/A'
      }
    },
    formatQuantity() {
      return function(item) {
        return item > 0 ? item : ''
      }
    },
    comparisonClass(item) {
      return function(item) {
        return {
          green: item > 0,
          red: item < 0
        }
      }
    },
    formatDate(date) {
      return function(date) {
        const dateFormat = this.$store.state.user.settings.dateFormat
        return moment(date, 'YYYY-MM-DD').format(dateFormat)
      }
    }
  },
  mounted() {
    this.$emit('get-bid-offer')
  }
}
</script>
