import { client } from '~/api/graphQLInstance'
import XeroAccessToken from '~/models/xeroAccessToken'

export const getAccess = async () => {
  const result = await client.query({
    query: XeroAccessToken.query('getAccess')
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }
  return result.data[XeroAccessToken.queries.getAccess.name]
}
