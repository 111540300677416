import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const log = console // on server-side, consola will catch all console.log
const VUEX_PROPERTIES = ['state', 'getters', 'actions', 'mutations']
let store = {}

void (function updateModules() {
  store = normalizeRoot(require('../store/index.js'), 'store/index.js')

  // If store is an exported method = classic mode (deprecated)

  // Enforce store modules
  store.modules = store.modules || {}

  resolveStoreModules(require('../store/lwinDictionary.js'), 'lwinDictionary.js')
  resolveStoreModules(require('../store/user.js'), 'user.js')
  resolveStoreModules(require('../store/formats.js'), 'formats.js')
  resolveStoreModules(require('../store/global.js'), 'global.js')
  resolveStoreModules(require('../store/lists.js'), 'lists.js')
  resolveStoreModules(require('../store/favourites.js'), 'favourites.js')
  resolveStoreModules(require('../store/menus.js'), 'menus.js')
  resolveStoreModules(require('../store/modal.js'), 'modal.js')
  resolveStoreModules(require('../store/users.js'), 'users.js')
  resolveStoreModules(require('../store/systemsettings.js'), 'systemsettings.js')
  resolveStoreModules(require('../store/reserves/categoryProducers.js'), 'reserves/categoryProducers.js')
  resolveStoreModules(require('../store/administration/promotionprofiles.js'), 'administration/promotionprofiles.js')
  resolveStoreModules(require('../store/administration/rates.js'), 'administration/rates.js')
  resolveStoreModules(require('../store/administration/shipperaddresses.js'), 'administration/shipperaddresses.js')
  resolveStoreModules(require('../store/administration/vineyards.js'), 'administration/vineyards.js')
  resolveStoreModules(require('../store/administration/warehouseaddresses.js'), 'administration/warehouseaddresses.js')
  resolveStoreModules(require('../store/administration/winematcher.js'), 'administration/winematcher.js')
  resolveStoreModules(require('../store/administration/winenames.js'), 'administration/winenames.js')
  resolveStoreModules(require('../store/administration/winesearcher.js'), 'administration/winesearcher.js')
  resolveStoreModules(require('../store/broking/contactMatches.js'), 'broking/contactMatches.js')
  resolveStoreModules(require('../store/broking/contacts.js'), 'broking/contacts.js')
  resolveStoreModules(require('../store/broking/tabs.js'), 'broking/tabs.js')
  resolveStoreModules(require('../store/contacts/addresses.js'), 'contacts/addresses.js')
  resolveStoreModules(require('../store/contacts/contacts.js'), 'contacts/contacts.js')
  resolveStoreModules(require('../store/contacts/recommendedwines.js'), 'contacts/recommendedwines.js')
  resolveStoreModules(require('../store/contacts/warehouseaccounts.js'), 'contacts/warehouseaccounts.js')
  resolveStoreModules(require('../store/deliveries/deliveries.js'), 'deliveries/deliveries.js')
  resolveStoreModules(require('../store/etally/cards.js'), 'etally/cards.js')
  resolveStoreModules(require('../store/etally/cardSearch.js'), 'etally/cardSearch.js')
  resolveStoreModules(require('../store/etally/checkIn.js'), 'etally/checkIn.js')
  resolveStoreModules(require('../store/etally/purchaseOrders.js'), 'etally/purchaseOrders.js')
  resolveStoreModules(require('../store/etally/returns.js'), 'etally/returns.js')
  resolveStoreModules(require('../store/wineCard/notes.js'), 'wineCard/notes.js')
  resolveStoreModules(require('../store/wineCard/emptyCases.js'), 'wineCard/emptyCases.js')
  resolveStoreModules(require('../store/wineCard/activeCard.js'), 'wineCard/activeCard.js')
  resolveStoreModules(require('../store/wineBrowser/wineList.js'), 'wineBrowser/wineList.js')
  resolveStoreModules(require('../store/wineBrowser/wineCards.js'), 'wineBrowser/wineCards.js')
  resolveStoreModules(require('../store/wineBrowser/vintages.js'), 'wineBrowser/vintages.js')
  resolveStoreModules(require('../store/wineBrowser/search.js'), 'wineBrowser/search.js')
  resolveStoreModules(require('../store/wineBrowser/filters.js'), 'wineBrowser/filters.js')
  resolveStoreModules(require('../store/etally/unallocatedSearch.js'), 'etally/unallocatedSearch.js')
  resolveStoreModules(require('../store/etally/unallocatedSearchInCard.js'), 'etally/unallocatedSearchInCard.js')
  resolveStoreModules(require('../store/etally/unallocatedSupplier.js'), 'etally/unallocatedSupplier.js')
  resolveStoreModules(require('../store/etally/unallocatedSupplierInCard.js'), 'etally/unallocatedSupplierInCard.js')
  resolveStoreModules(require('../store/export/email.js'), 'export/email.js')
  resolveStoreModules(require('../store/export/settings.js'), 'export/settings.js')
  resolveStoreModules(require('../store/export/wineList.js'), 'export/wineList.js')
  resolveStoreModules(require('../store/administration/abvreport.js'), 'administration/abvreport.js')
  resolveStoreModules(require('../store/administration/classifications.js'), 'administration/classifications.js')
  resolveStoreModules(require('../store/administration/countries.js'), 'administration/countries.js')
  resolveStoreModules(require('../store/administration/countryregions.js'), 'administration/countryregions.js')
  resolveStoreModules(require('../store/administration/countrysubregions.js'), 'administration/countrysubregions.js')
  resolveStoreModules(require('../store/administration/designations.js'), 'administration/designations.js')
  resolveStoreModules(require('../store/administration/dutytypes.js'), 'administration/dutytypes.js')
  resolveStoreModules(require('../store/administration/formats.js'), 'administration/formats.js')
  resolveStoreModules(require('../store/purchaseOrders/import.js'), 'purchaseOrders/import.js')
  resolveStoreModules(require('../store/purchaseOrders/purchaseMatches.js'), 'purchaseOrders/purchaseMatches.js')
  resolveStoreModules(require('../store/purchaseOrders/purchaseOrder.js'), 'purchaseOrders/purchaseOrder.js')
  resolveStoreModules(require('../store/wineBrowser/favourites.js'), 'wineBrowser/favourites.js')
  resolveStoreModules(require('../store/wineBrowser/countries.js'), 'wineBrowser/countries.js')
  resolveStoreModules(require('../store/wineBrowser/categorySubRegions.js'), 'wineBrowser/categorySubRegions.js')
  resolveStoreModules(require('../store/wineBrowser/categoryProducers.js'), 'wineBrowser/categoryProducers.js')
  resolveStoreModules(require('../store/wineBrowser/categories.js'), 'wineBrowser/categories.js')
  resolveStoreModules(require('../store/wineBrowser/appellations.js'), 'wineBrowser/appellations.js')
  resolveStoreModules(require('../store/web/webhooks.js'), 'web/webhooks.js')
  resolveStoreModules(require('../store/web/salesOrders.js'), 'web/salesOrders.js')
  resolveStoreModules(require('../store/quicksearch/quicksearch.js'), 'quicksearch/quicksearch.js')
  resolveStoreModules(require('../store/reports/reports.js'), 'reports/reports.js')
  resolveStoreModules(require('../store/reserves/appellations.js'), 'reserves/appellations.js')
  resolveStoreModules(require('../store/reserves/categories.js'), 'reserves/categories.js')
  resolveStoreModules(require('../store/administration/appellations.js'), 'administration/appellations.js')
  resolveStoreModules(require('../store/reserves/categorySubRegions.js'), 'reserves/categorySubRegions.js')
  resolveStoreModules(require('../store/reserves/contactMatches.js'), 'reserves/contactMatches.js')
  resolveStoreModules(require('../store/reserves/countries.js'), 'reserves/countries.js')
  resolveStoreModules(require('../store/reserves/customerPurchaseOrders.js'), 'reserves/customerPurchaseOrders.js')
  resolveStoreModules(require('../store/reserves/customerSalesOrders.js'), 'reserves/customerSalesOrders.js')
  resolveStoreModules(require('../store/reserves/customerStockCard.js'), 'reserves/customerStockCard.js')
  resolveStoreModules(require('../store/reserves/filters.js'), 'reserves/filters.js')
  resolveStoreModules(require('../store/reserves/reserves.js'), 'reserves/reserves.js')
  resolveStoreModules(require('../store/reserves/search.js'), 'reserves/search.js')
  resolveStoreModules(require('../store/reserves/vintages.js'), 'reserves/vintages.js')
  resolveStoreModules(require('../store/reserves/wineCards.js'), 'reserves/wineCards.js')
  resolveStoreModules(require('../store/reserves/wineList.js'), 'reserves/wineList.js')
  resolveStoreModules(require('../store/reserves/wineStockCard.js'), 'reserves/wineStockCard.js')
  resolveStoreModules(require('../store/web/purchaseOrders.js'), 'web/purchaseOrders.js')
  resolveStoreModules(require('../store/reviews/old-reviews.js'), 'reviews/old-reviews.js')
  resolveStoreModules(require('../store/reviews/reviews.js'), 'reviews/reviews.js')
  resolveStoreModules(require('../store/rotationCard/rotationCard.js'), 'rotationCard/rotationCard.js')
  resolveStoreModules(require('../store/salesOrders/oversold.js'), 'salesOrders/oversold.js')
  resolveStoreModules(require('../store/salesOrders/salesMatches.js'), 'salesOrders/salesMatches.js')
  resolveStoreModules(require('../store/salesOrders/salesOrder.js'), 'salesOrders/salesOrder.js')
  resolveStoreModules(require('../store/web/purchaseOrder.js'), 'web/purchaseOrder.js')
  resolveStoreModules(require('../store/web/openPurchaseOrders.js'), 'web/openPurchaseOrders.js')
  resolveStoreModules(require('../store/web/oauth.js'), 'web/oauth.js')
  resolveStoreModules(require('../store/web/contactSalesOrders.js'), 'web/contactSalesOrders.js')
  resolveStoreModules(require('../store/web/contacts.js'), 'web/contacts.js')
  resolveStoreModules(require('../store/web/contactPurchaseOrders.js'), 'web/contactPurchaseOrders.js')
  resolveStoreModules(require('../store/web/changes.js'), 'web/changes.js')
  resolveStoreModules(require('../store/administration/lcbdeliverycharges.js'), 'administration/lcbdeliverycharges.js')
  resolveStoreModules(require('../store/shipping/salesMatches.js'), 'shipping/salesMatches.js')
  resolveStoreModules(require('../store/stockCard/stockCard.js'), 'stockCard/stockCard.js')
  resolveStoreModules(require('../store/storage/billingRun.js'), 'storage/billingRun.js')
  resolveStoreModules(require('../store/storage/billingRunList.js'), 'storage/billingRunList.js')
  resolveStoreModules(require('../store/storage/storagePricingGroup.js'), 'storage/storagePricingGroup.js')
  resolveStoreModules(require('../store/storage/tabs.js'), 'storage/tabs.js')
  resolveStoreModules(require('../store/administration/photoparameters.js'), 'administration/photoparameters.js')
  resolveStoreModules(require('../store/administration/producers.js'), 'administration/producers.js')
  resolveStoreModules(require('../store/xero/xero.js'), 'xero/xero.js')
  resolveStoreModules(require('../store/salesOrders/search/wineList.js'), 'salesOrders/search/wineList.js')
  resolveStoreModules(require('../store/salesOrders/search/vintages.js'), 'salesOrders/search/vintages.js')
  resolveStoreModules(require('../store/salesOrders/search/search.js'), 'salesOrders/search/search.js')
  resolveStoreModules(require('../store/salesOrders/search/filters.js'), 'salesOrders/search/filters.js')
  resolveStoreModules(require('../store/salesOrders/search/countries.js'), 'salesOrders/search/countries.js')
  resolveStoreModules(require('../store/salesOrders/search/categories.js'), 'salesOrders/search/categories.js')
  resolveStoreModules(require('../store/salesOrders/search/appellations.js'), 'salesOrders/search/appellations.js')
  resolveStoreModules(require('../store/reserves/wineStockCard/filters.js'), 'reserves/wineStockCard/filters.js')
  resolveStoreModules(require('../store/purchaseOrders/search/wineList.js'), 'purchaseOrders/search/wineList.js')
  resolveStoreModules(require('../store/web/search/appellations.js'), 'web/search/appellations.js')
  resolveStoreModules(require('../store/web/search/categories.js'), 'web/search/categories.js')
  resolveStoreModules(require('../store/web/search/countries.js'), 'web/search/countries.js')
  resolveStoreModules(require('../store/web/search/filters.js'), 'web/search/filters.js')
  resolveStoreModules(require('../store/web/search/search.js'), 'web/search/search.js')
  resolveStoreModules(require('../store/web/search/vintages.js'), 'web/search/vintages.js')
  resolveStoreModules(require('../store/web/search/wineCards.js'), 'web/search/wineCards.js')
  resolveStoreModules(require('../store/salesOrders/search/wineCards.js'), 'salesOrders/search/wineCards.js')
  resolveStoreModules(require('../store/purchaseOrders/search/wineCards.js'), 'purchaseOrders/search/wineCards.js')
  resolveStoreModules(require('../store/purchaseOrders/search/vintages.js'), 'purchaseOrders/search/vintages.js')
  resolveStoreModules(require('../store/purchaseOrders/search/search.js'), 'purchaseOrders/search/search.js')
  resolveStoreModules(require('../store/purchaseOrders/search/filters.js'), 'purchaseOrders/search/filters.js')
  resolveStoreModules(require('../store/purchaseOrders/search/countries.js'), 'purchaseOrders/search/countries.js')
  resolveStoreModules(require('../store/purchaseOrders/search/categories.js'), 'purchaseOrders/search/categories.js')
  resolveStoreModules(require('../store/purchaseOrders/search/appellations.js'), 'purchaseOrders/search/appellations.js')
  resolveStoreModules(require('../store/etally/search/wineList.js'), 'etally/search/wineList.js')
  resolveStoreModules(require('../store/etally/search/wineCards.js'), 'etally/search/wineCards.js')
  resolveStoreModules(require('../store/etally/search/vintages.js'), 'etally/search/vintages.js')
  resolveStoreModules(require('../store/etally/search/search.js'), 'etally/search/search.js')
  resolveStoreModules(require('../store/etally/search/filters.js'), 'etally/search/filters.js')
  resolveStoreModules(require('../store/etally/search/countries.js'), 'etally/search/countries.js')
  resolveStoreModules(require('../store/etally/search/categories.js'), 'etally/search/categories.js')
  resolveStoreModules(require('../store/etally/search/appellations.js'), 'etally/search/appellations.js')
  resolveStoreModules(require('../store/web/search/wineList.js'), 'web/search/wineList.js')

  // If the environment supports hot reloading...
})()

// createStore
export const createStore = store instanceof Function ? store : () => {
  return new Vuex.Store(Object.assign({
    strict: (process.env.NODE_ENV !== 'production')
  }, store))
}

function resolveStoreModules(moduleData, filename) {
  moduleData = moduleData.default || moduleData
  // Remove store src + extension (./foo/index.js -> foo/index)
  const namespace = filename.replace(/\.(js|mjs|ts)$/, '')
  const namespaces = namespace.split('/')
  let moduleName = namespaces[namespaces.length - 1]
  const filePath = `store/${filename}`

  moduleData = moduleName === 'state'
    ? normalizeState(moduleData, filePath)
    : normalizeModule(moduleData, filePath)

  // If src is a known Vuex property
  if (VUEX_PROPERTIES.includes(moduleName)) {
    const property = moduleName
    const storeModule = getStoreModule(store, namespaces, { isProperty: true })

    // Replace state since it's a function
    mergeProperty(storeModule, moduleData, property)
    return
  }

  // If file is foo/index.js, it should be saved as foo
  const isIndexModule = (moduleName === 'index')
  if (isIndexModule) {
    namespaces.pop()
    moduleName = namespaces[namespaces.length - 1]
  }

  const storeModule = getStoreModule(store, namespaces)

  for (const property of VUEX_PROPERTIES) {
    mergeProperty(storeModule, moduleData[property], property)
  }

  if (moduleData.namespaced === false) {
    delete storeModule.namespaced
  }
}

function normalizeRoot(moduleData, filePath) {
  moduleData = moduleData.default || moduleData

  if (moduleData.commit) {
    throw new Error(`[nuxt] ${filePath} should export a method that returns a Vuex instance.`)
  }

  if (typeof moduleData !== 'function') {
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData)
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeState(moduleData, filePath) {
  if (typeof moduleData !== 'function') {
    log.warn(`${filePath} should export a method that returns an object`)
    const state = Object.assign({}, moduleData)
    return () => state
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeModule(moduleData, filePath) {
  if (moduleData.state && typeof moduleData.state !== 'function') {
    log.warn(`'state' should be a method that returns an object in ${filePath}`)
    const state = Object.assign({}, moduleData.state)
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData, { state: () => state })
  }
  return moduleData
}

function getStoreModule(storeModule, namespaces, { isProperty = false } = {}) {
  // If ./mutations.js
  if (!namespaces.length || (isProperty && namespaces.length === 1)) {
    return storeModule
  }

  const namespace = namespaces.shift()

  storeModule.modules[namespace] = storeModule.modules[namespace] || {}
  storeModule.modules[namespace].namespaced = true
  storeModule.modules[namespace].modules = storeModule.modules[namespace].modules || {}

  return getStoreModule(storeModule.modules[namespace], namespaces, { isProperty })
}

function mergeProperty(storeModule, moduleData, property) {
  if (!moduleData) return

  if (property === 'state') {
    storeModule.state = moduleData || storeModule.state
  } else {
    storeModule[property] = Object.assign({}, storeModule[property], moduleData)
  }
}
